import React from 'react';

function Header() {
  const scrollToSection = (sectionId) => {
    if (sectionId === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        const headerOffset = 100; // Increased offset to prevent overlap
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <header className="bg-[#1a1333] text-white py-2 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Larger logo with less padding */}
          <div className="w-20 h-20 -my-2" onClick={() => scrollToSection('top')}>
            <img 
              src="/images/Activated-Human-Logo-INCONIC.png" 
              alt="Activated Human Logo" 
              className="w-full h-full object-contain"
            />
          </div>

          <nav className="flex items-center space-x-8">
            <button 
              onClick={() => scrollToSection('top')} 
              className="text-white hover:text-blue-200 transition duration-300"
            >
              Home
            </button>
            <button 
              onClick={() => scrollToSection('transformation')} 
              className="text-white hover:text-blue-200 transition duration-300"
            >
              Benefits
            </button>
            <button 
              onClick={() => scrollToSection('pricing')} 
              className="text-white hover:text-blue-200 transition duration-300"
            >
              Pricing
            </button>
          </nav>

          <div className="flex space-x-4">
            <a 
              href="https://life.activatedhuman.earth" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-white text-[#1a1333] px-6 py-2 rounded-full hover:bg-blue-50 transition duration-300 text-sm font-semibold"
            >
              Try it Out
            </a>
            <a 
              href="https://checkout.activatedhuman.earth/b/4gw7sCeDlaOY8y4144" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-[#ccff00] text-[#1a1333] px-6 py-2 rounded-full hover:bg-blue-50 transition duration-300 text-sm font-semibold"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
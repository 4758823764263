import React, { useState } from 'react';

const Pricing = ({ checkoutUrl }) => {
  const [billingInterval, setBillingInterval] = useState('yearly');

  const plans = {
    weekly: {
      price: 25,
      period: 'week',
      savings: null,
    },
    monthly: {
      price: 99,
      period: 'month',
      savings: null,
    },
    yearly: {
      price: 79,
      period: 'month',
      savings: 'Save $240',
    }
  };

  const billingOptions = ['weekly', 'monthly', 'yearly'];

  const features = [
    "Unlimited AI tasks & prompts",
    "Daily personalized insights, reminders, & assistance",
    "24/7 proactive life-enhancing strategy & guidance"
  ];

  return (
    <section id="pricing" className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">Trusted AI with Simple, Transparent Pricing</h2>
        
        {/* Billing Interval Selector */}
        <div className="flex justify-center space-x-4 mb-8">
          {billingOptions.map((interval) => (
            <button
              key={interval}
              onClick={() => setBillingInterval(interval)}
              className={`px-6 py-2 rounded-full font-medium transition-all ${
                billingInterval === interval
                  ? 'bg-blue-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              {interval.charAt(0).toUpperCase() + interval.slice(1)}
            </button>
          ))}
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8">
          <h3 className="text-2xl font-semibold mb-4">
            {billingInterval.charAt(0).toUpperCase() + billingInterval.slice(1)} Plan
          </h3>
          
          <div className="mb-6">
            <p className="text-5xl font-bold">
              ${plans[billingInterval].price}
              <span className="text-xl text-gray-600">/{plans[billingInterval].period}</span>
            </p>
            {plans[billingInterval].savings && (
              <p className="text-green-500 font-medium mt-2">
                {plans[billingInterval].savings}
              </p>
            )}
          </div>

          <ul className="text-left mb-8">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center mb-2">
                <svg 
                  className="w-5 h-5 text-green-500 mr-2" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {feature}
              </li>
            ))}
          </ul>

          <a 
            href={`${checkoutUrl}?plan=${billingInterval}`} 
            className="inline-block bg-blue-600 text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-blue-700 transition duration-300"
          >
            Start Your Journey Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
import React from "react";
import { motion } from 'framer-motion';

const Hero = () => (
  <section className="relative bg-gray-900 text-white overflow-hidden">
    {/* Responsive header image with reduced height and cropping */}
    <div className="w-full h-0 pb-[48%] relative ">
      <img 
        src="/images/earth_landing.png" 
        alt="Everything you need is inside of you" 
        className="absolute top-0 left-0 w-full h-auto object-cover"
      />
    </div>

  </section>
);

export default Hero;
import React from "react";
import { motion } from 'framer-motion';

const ProductDemo_2 = () => (
  <section className="relative bg-gray-900 text-white overflow-hidden">
    {/* Responsive header image with reduced height and cropping */}
    <div className="w-full h-0 pb-[55%] relative ">
      <img 
        src="/images/iMac.png" 
        alt="Product image of Activated Human on Desktop Computer" 
        className="absolute top-0 left-0 w-full h-auto object-cover"
      />
    </div>

  </section>
);

export default ProductDemo_2;
import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Linkedin, Instagram, Globe } from 'lucide-react';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };
  return (
    <footer className="bg-gray-900 text-gray-300 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">COMPANY</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-white transition-colors" onClick={scrollToTop}>About Activated Human</Link></li>
              {/* <li><Link to="/newsroom" className="hover:text-white transition-colors" onClick={scrollToTop}>Newsroom</Link></li> */}
              <li><Link to="/contact" className="hover:text-white transition-colors" onClick={scrollToTop}>Contact us</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">PLATFORM</h3>
            <ul className="space-y-2">
              <li><Link to="/life-framework" className="hover:text-white transition-colors" onClick={scrollToTop}>Life Framework</Link></li>
              {/* <li><Link to="/home-assistant" className="hover:text-white transition-colors" onClick={scrollToTop}>Home Assistant</Link></li> */}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">RESOURCES</h3>
            <ul className="space-y-2">
              <li><Link to="/blog" className="hover:text-white transition-colors" onClick={scrollToTop}>Blog</Link></li>
              <li><Link to="/help-center" className="hover:text-white transition-colors" onClick={scrollToTop}>Help Center</Link></li>
              {/* <li><Link to="/community" className="hover:text-white transition-colors" onClick={scrollToTop}>Community</Link></li> */}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">LEGAL</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="hover:text-white transition-colors" onClick={scrollToTop}>Terms of Service</Link></li>
              <li><Link to="/privacy" className="hover:text-white transition-colors" onClick={scrollToTop}>Privacy Policy</Link></li>
              {/* <li><Link to="/gdpr" className="hover:text-white transition-colors" onClick={scrollToTop}>GDPR Compliance</Link></li> */}
            </ul>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-700 pt-8">
          <div className="mb-8 md:mb-0">
            <img src="/images/Activated-Human-NEW-Text-small.png" alt="Activated Human Logo" className="h-16" />
            <p className="mt-2">Power smarter personal growth</p>
          </div>
          <div className="flex space-x-4">
            <a href="#" className="text-gray-400 hover:text-white transition-colors"><Facebook size={20} /></a>
            <a href="#" className="text-gray-400 hover:text-white transition-colors"><Twitter size={20} /></a>
            <a href="#" className="text-gray-400 hover:text-white transition-colors"><Linkedin size={20} /></a>
            <a href="#" className="text-gray-400 hover:text-white transition-colors"><Instagram size={20} /></a>
          </div>
        </div>

        <div className="mt-8 flex flex-col md:flex-row justify-between items-center text-sm">
          <p className="text-center md:text-left mb-4 md:mb-0">
            Neon art and road signs are created with love and intention by light artist{' '}
            <a 
              href="https://www.oliviasteele.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline"
            >
              Olivia Steele, 
            </a>
            {' '}
            <a 
              href="https://www.instagram.com/steeleism/?hl=en" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline"
            >
            @steelism
            </a>
          </p>
        </div>
        
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center text-sm">
          <p>&copy; 2024 Activated Human LLC. All rights reserved.</p>
          <div className="flex items-center mt-4 md:mt-0">
            <Globe size={16} className="mr-2" />
            <select className="bg-transparent border-none text-gray-400 focus:outline-none">
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
            </select>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
import React from "react";
import { motion } from 'framer-motion';

const EverythingYouNeed = () => (
  <section className="relative bg-gray-900 text-white overflow-hidden">
    {/* Responsive header image with reduced height and cropping */}
    <div className="w-full h-0 pb-[48%] relative ">
      <img 
        src="/images/Olivia-Steele-Hero.png"
        // src="/images/Activated-Human-NEW-Web-elements_2.jpg"
        alt="Everything you need is inside of you" 
        className="absolute top-0 left-0 w-full h-auto object-cover"
      />
    </div>

  </section>
);

export default EverythingYouNeed;
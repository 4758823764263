import React from 'react';
import { motion } from 'framer-motion';

import Pricing from './Pricing'
import Features from './Features'
import Testimonials from './Testimonials'

import Hero from '../landing/Hero'
import Hero_2 from '../landing/Hero_2'
import Hero_3 from '../landing/Hero_3'
import ProductDemo from '../landing/ProductDemo'
import ProductDemo_2 from '../landing/ProductDemo_2'
import EverythingYouNeed from '../landing/EverythingYouNeed'


const STRIPE_CHECKOUT_URL = 'https://checkout.activatedhuman.earth/b/4gw7sCeDlaOY8y4144';


const LifeFrameworkHome = () => {
  return (
    <div className="bg-gradient-to-b from-indigo-50 via-blue-50 to-white min-h-screen">
      <Hero />
      <ProductDemo />
      <Hero_2 />
      <LifeFrameworkHero />
      <Hero_3 />
      <Transformation />
      
      <Features />
      {/* <HowItWorks /> */}
      <Pricing checkoutUrl={STRIPE_CHECKOUT_URL} />
      {/* <Testimonials /> */}
      <ProductDemo_2 />
      
      <EverythingYouNeed />
      {/* <CallToAction /> */}
    </div>
  );
};




const LifeFrameworkHero = () => (
  <section className="py-24 px-4 sm:px-6 lg:px-8">
    <div className="max-w-6xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center"
      >
        <h1 className="text-5xl md:text-7xl font-bold text-gray-900 mb-8 leading-tight">
          Navigate Life's Pivotal Moments with <span className="text-blue-600">Blue</span>
        </h1>
        <p className="text-xl md:text-2xl text-gray-700 mb-10 max-w-3xl mx-auto leading-relaxed">
          Your 24/7 companion for personal transformation, emotional support, and breakthrough moments.
        </p>
        <div className="space-x-4">
          <a href="https://life.activatedhuman.earth"
             className="bg-blue-600 text-white font-semibold py-4 px-8 rounded-full text-lg hover:bg-blue-700 transition duration-300 shadow-lg hover:shadow-xl">
            Begin Your Journey
          </a>
          {/* <a href="#demo" 
             className="bg-white text-blue-600 font-semibold py-4 px-8 rounded-full text-lg border-2 border-blue-600 hover:bg-blue-50 transition duration-300">
            See How It Works
          </a> */}
        </div>
      </motion.div>
    </div>
  </section>
);



const HowItWorks = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">How Blue Works</h2>
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div>
          <ol className="space-y-6">
            {[
              "Connect your existing tools (Telegram, Todoist)",
              "Answer a few questions about your goals and lifestyle",
              "Engage with Blue through natural conversations",
              "Receive daily personalized insights and tasks",
              "Track your progress and adjust your path as needed"
            ].map((step, index) => (
              <li key={index} className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4">{index + 1}</span>
                <span>{step}</span>
              </li>
            ))}
          </ol>
        </div>
        <div className="bg-gray-200 h-80 rounded-lg flex items-center justify-center">
          {/* Placeholder for an interactive demo or screenshot */}
          <p className="text-2xl text-gray-600">Interactive Demo</p>
        </div>
      </div>
    </div>
  </section>
);



const Transformation = () => (
  <section id='transformation' className="py-20 px-4 bg-white">
    <div className="max-w-6xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-16">Transform Your Life Through Deep Understanding</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <TransformationCard
          title="Life Strategy"
          description="Find clarity and direction in life's challenging moments with immediate, insightful support."
          highlight="Available 24/7 when you need guidance most"
        />
        <TransformationCard
          title="Personal Evolution"
          description="Develop deeper self-understanding and growth through continuous reflection and guidance."
          highlight="Your journey of transformation starts here"
        />
        <TransformationCard
          title="Emotional Intelligence"
          description="Process complex emotions and decisions with a partner who truly understands your journey."
          highlight="Beyond chat - real day-to-day support"
        />
      </div>
    </div>
  </section>
);

const TransformationCard = ({ title, description, highlight }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="bg-gradient-to-b from-blue-50 to-white p-8 rounded-2xl shadow-lg"
  >
    <h3 className="text-2xl font-bold mb-4 text-gray-900">{title}</h3>
    <p className="text-gray-700 mb-4">{description}</p>
    <p className="text-blue-600 font-semibold">{highlight}</p>
  </motion.div>
);



const CallToAction = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-blue-600 text-white">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-6">Ready to Optimize Your Life?</h2>
      <p className="text-xl mb-8">Join thousands of users who are already experiencing the benefits of AI-driven personal growth.</p>
      <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-white text-blue-600 font-semibold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
        Start Your Free Trial Now
      </a>
    </div>
  </section>
);

export default LifeFrameworkHome;
import React from 'react';
import KlaviyoForm from './KlaviyoForm';

const EmailSignupSection = () => (
  <section className="py-20 bg-gray-50">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-8 text-blue-900">Sign Up for Updates</h2>
      <p className="text-xl text-gray-600 mb-6 max-w-3xl mx-auto">
        Join our mailing list to receive the latest news and updates from Activated Human.
      </p>
      <KlaviyoForm />
    </div>
  </section>
);

export default EmailSignupSection;
import React from 'react';
import { Mail,  } from 'lucide-react';

import EmailSignup from './landing/EmailSignup';

import Hero from './landing/Hero'
import Hero_2 from './landing/Hero_2'
import Hero_3 from './landing/Hero_3'
import ProductDemo from './landing/ProductDemo'
import ProductDemo_2 from './landing/ProductDemo_2'
import Products from './landing/Products';
import ProductShowcase from './landing/ProductShowcase';
import VisionSection from './landing/Vision';


function ActivatedHumanLanding() {
  return (
    <div>
      <Hero />
      <ProductDemo />
      <ProductDemo_2 />
      <Hero_2 />
      <Hero_3 />
      <VisionSection />
      {/* <Products /> */}
      {/* <ProductShowcase /> */}
      <EmailSignup />
      <About />
      {/* <Testimonials /> */}
      <CallToAction />
      <GetInTouch />
    </div>
  );
}



const About = () => (
  <section className="py-20 bg-gray-50">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-8 text-blue-900">About Activated Human</h2>
      <p className="text-xl text-gray-600 mb-6 max-w-3xl mx-auto">
        At Activated Human, we're passionate about leveraging cutting-edge AI technology to empower individuals in their personal growth and self-development journeys.
      </p>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        Our mission is to provide innovative tools that enhance well-being, boost productivity, and accelerate learning, helping you unlock your full potential.
      </p>
    </div>
  </section>
);

const Testimonials = () => (
  <section className="py-20 bg-gradient-to-br from-blue-900 to-purple-700 text-white">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold text-center mb-12">What Our Users Say</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          { name: "Sarah K.", role: "Entrepreneur", quote: "Activated Human has transformed the way I approach my daily tasks and long-term goals." },
          { name: "Michael R.", role: "Software Engineer", quote: "The personalized insights I get have helped me break through plateaus in both my personal and professional life." },
          { name: "Emily T.", role: "Language Enthusiast", quote: "LinguaBoost has accelerated my language learning journey beyond what I thought was possible." }
        ].map((testimonial, index) => (
          <div key={index} className="bg-white bg-opacity-10 p-6 rounded-xl">
            <p className="text-lg mb-4">"{testimonial.quote}"</p>
            <p className="font-semibold">{testimonial.name}</p>
            <p className="text-sm text-blue-200">{testimonial.role}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <section className="py-20 bg-blue-900 text-white">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-8">Ready to Transform Your Life?</h2>
      <button className="bg-white text-blue-900 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300">
        Get Started Now
      </button>
    </div>
  </section>
);

const GetInTouch = () => (
  <section id="contact" className="py-20 px-4 sm:px-6 lg:px-8 bg-[#2a2a3c]">
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-8 text-white">Get in Touch</h2>
      <p className="text-xl text-gray-300 mb-8">Have questions about our AI-powered tools or need support? We're here to help!</p>
      <a 
        href="mailto:hello@activatedhuman.earth" 
        className="inline-flex items-center bg-[#8b5cf6] text-white font-semibold py-3 px-8 rounded-full hover:bg-[#7c3aed] transition duration-300"
      >
        <Mail className="w-5 h-5 mr-2" />
        Contact Us
      </a>
    </div>
  </section>
);


export default ActivatedHumanLanding;
import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Calendar, MessageSquare, Zap, Target, Sparkles } from 'lucide-react';

const Features = () => (
  <section id="journey" className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-5xl font-bold text-center mb-6">
        Transform Your Journey with <span className="text-blue-600">Blue</span>
      </h2>
      <p className="text-xl text-gray-600 text-center mb-16 max-w-3xl mx-auto">
        Experience deep support that adapts and grows with you, available whenever you need guidance
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <FeatureCard
          icon={Brain}
          title="Deep Understanding 👀"
          description="Experience conversations that truly see you, uncovering patterns and insights that drive personal growth."
          iconColor="#F44C39"
        />
        <FeatureCard
          icon={Calendar}
          title="Life Flow Mastery 🎯"
          description="Create harmony in your daily rhythm with intelligent support that adapts to your unique patterns and needs."
          iconColor="#775187"
        />
        <FeatureCard
          icon={MessageSquare}
          title="Always Present Support ✨"
          description="Find guidance whenever you need it, with thoughtful responses that help navigate life's complexities."
          iconColor="#F29436"
        />
        <FeatureCard
          icon={Zap}
          title="Transformative Growth 🌱"
          description="Develop powerful new patterns and perspectives through guided exploration and reflection."
          iconColor="#FED569"
        />
        <FeatureCard
          icon={Target}
          title="Breakthrough Moments 💫"
          description="Turn challenges into opportunities with strategic guidance that helps you see new possibilities."
          iconColor="#3472DE"
        />
        <FeatureCard
          icon={Sparkles}
          title="Evolutionary Support 🚀"
          description="Experience continuous growth through a relationship that deepens and evolves with your journey."
          iconColor="#85E965"
        />
      </div>
    </div>
  </section>
);

const FeatureCard = ({ icon: Icon, title, description, iconColor }) => (
  <motion.div
    whileHover={{ y: -5, scale: 1.02 }}
    transition={{ duration: 0.2 }}
    className="bg-white p-8 rounded-xl shadow-lg border border-gray-100 hover:shadow-xl"
  >
    <div className="flex items-center mb-4">
      <Icon className="w-8 h-8 mr-3" style={{ color: iconColor }} />
      <h3 className="text-xl font-bold text-gray-900">{title}</h3>
    </div>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </motion.div>
);


export default Features;

import React from "react";
import { motion } from 'framer-motion';

const Hero2 = () => (
  <section className="relative bg-gray-900 text-white overflow-hidden">
    <div className="w-full h-0 pb-[51%] relative overflow-hidden">
      <img 
        src="/images/mastering-day-to-day-2.png" 
        alt="Everything you need is inside of you" 
        className="absolute top-0 left-0 w-full h-auto object-cover"
      />
    </div>
  </section>
);

export default Hero2;
import React from 'react';
import { motion } from 'framer-motion';
import { Volume2, Shuffle, Clock, Globe, Headphones, BarChart } from 'lucide-react';

import EmailSignupSection from '../EmailSignup';

// Stripe checkout URL - replace with your actual URL for LinguaBoost
const STRIPE_CHECKOUT_URL = 'https://checkout.activatedhuman.earth/b/5kA7sC7aT0akaGcbIJ';

const LinguaBoostHome = () => {
  return (
    <div className="bg-gradient-to-b from-purple-50 to-white">
      <Hero />
      <EmailSignupSection /> 
      <Features />
      <HowItWorks />
      <Pricing />
      <Testimonials />
      <CallToAction />
    </div>
  );
};


const Hero = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 mb-6">
          Boost Your <span className="text-purple-600">Language Learning</span> Journey
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Expand your vocabulary effortlessly throughout your day with audio-based learning.
        </p>
        <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-purple-600 text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-purple-700 transition duration-300">
          Start Your Free Trial
        </a>
      </motion.div>
    </div>
  </section>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white p-6 rounded-xl shadow-lg"
  >
    <Icon className="w-12 h-12 text-purple-600 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const Features = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">Why Choose LinguaBoost?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <FeatureCard
          icon={Volume2}
          title="Audio-Based Learning"
          description="Learn new words through listening, enhancing your pronunciation and comprehension."
        />
        <FeatureCard
          icon={Shuffle}
          title="Smart Shuffling"
          description="Our algorithm ensures optimal repetition for maximum retention of new vocabulary."
        />
        <FeatureCard
          icon={Clock}
          title="Learn Anytime, Anywhere"
          description="Seamlessly integrate language learning into your daily routine."
        />
        <FeatureCard
          icon={Globe}
          title="Multiple Languages"
          description="Choose from a variety of language pairs to suit your learning needs."
        />
        <FeatureCard
          icon={Headphones}
          title="Native Pronunciations"
          description="Hear words and definitions spoken by native speakers for authentic learning."
        />
        <FeatureCard
          icon={BarChart}
          title="Progress Tracking"
          description="Monitor your vocabulary growth and learning streaks to stay motivated."
        />
      </div>
    </div>
  </section>
);

const HowItWorks = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">How LinguaBoost Works</h2>
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div>
          <ol className="space-y-6">
            {[
              "Choose your target language and vocabulary set",
              "Set your preferred learning intervals",
              "Listen to words in your target language",
              "Hear definitions in your native language",
              "Gradually expand your vocabulary throughout the day"
            ].map((step, index) => (
              <li key={index} className="flex items-center">
                <span className="bg-purple-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4">{index + 1}</span>
                <span>{step}</span>
              </li>
            ))}
          </ol>
        </div>
        <div className="bg-gray-200 h-80 rounded-lg flex items-center justify-center">
          {/* Placeholder for an app screenshot or demo video */}
          <p className="text-2xl text-gray-600">App Demo</p>
        </div>
      </div>
    </div>
  </section>
);

const Pricing = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-8">Flexible Pricing Plans</h2>
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h3 className="text-2xl font-semibold mb-4">Monthly Subscription</h3>
        <p className="text-5xl font-bold mb-6">$7.99<span className="text-xl text-gray-600">/month</span></p>
        <ul className="text-left mb-8">
          {[
            "Access to all language pairs",
            "Unlimited daily word sessions",
            "Progress tracking and analytics",
            "New vocabulary sets added regularly",
            "Cancel anytime"
          ].map((feature, index) => (
            <li key={index} className="flex items-center mb-2">
              <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
              {feature}
            </li>
          ))}
        </ul>
        <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-purple-600 text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-purple-700 transition duration-300">
          Start 7-Day Free Trial
        </a>
      </div>
    </div>
  </section>
);

const Testimonials = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">What Our Learners Say</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          {
            quote: "LinguaBoost has revolutionized the way I learn new words. It's so convenient to learn while doing other tasks!",
            author: "Alex M., Learning Spanish"
          },
          {
            quote: "I've tried many language apps, but LinguaBoost's audio approach really helps the words stick in my memory.",
            author: "Sophia L., Learning French"
          },
          {
            quote: "As a busy professional, LinguaBoost allows me to make progress in my language goals without disrupting my schedule.",
            author: "David K., Learning Mandarin"
          }
        ].map((testimonial, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md">
            <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
            <p className="font-semibold">{testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-purple-600 text-white">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-6">Ready to Boost Your Language Skills?</h2>
      <p className="text-xl mb-8">Join thousands of learners who are expanding their vocabulary effortlessly with LinguaBoost.</p>
      <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-white text-purple-600 font-semibold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
        Start Your Free Trial Now
      </a>
    </div>
  </section>
);

export default LinguaBoostHome;